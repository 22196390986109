.translation-results {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 10px;
}
.translation-results ul {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.translation-results li {
  margin-right: 10px;
  background-color: #f0f0f0;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}

/* Contenedor de las tarjetas */
.cards {
  display: flex;
  flex-wrap: nowrap; /* Asegura que las tarjetas no se envuelvan */
  gap: 10px; /* Espaciado entre las tarjetas */
  overflow-x: auto; /* Habilita desplazamiento horizontal */
  padding: 10px;
  scroll-behavior: smooth; /* Desplazamiento suave */
  box-sizing: border-box;
}

/* Ocultar barra de desplazamiento en navegadores */
.cards::-webkit-scrollbar {
  height: 8px;
}

.cards::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 4px;
}

/* Tarjeta individual */
.card-item {
  flex: 0 0 150px; /* Tamaño fijo de las tarjetas */
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px;
}

/* Imagen dentro de la tarjeta */
.card-image-container {
  width: 100%;
  aspect-ratio: 5 / 7; /* Mantiene proporciones */
  overflow: hidden;
  border-radius: 8px;
}

.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ajusta la imagen sin distorsión */
}

/* Título y precio de la tarjeta */
.card-title {
  font-size: 0.9rem;
  margin: 5px 0;
}

.card-price {
  font-size: 0.8rem;
  color: #333;
}

/* Botón de guardar */
.save-button {
  margin-top: 10px;
  font-size: 0.75rem;
  padding: 5px 10px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.save-button:hover {
  background-color: #0056b3;
}

/* Responsividad */
@media (max-width: 768px) {
  .card-item {
    flex: 0 0 120px; /* Tamaño más pequeño en móviles */
  }

  .card-title {
    font-size: 0.8rem;
  }

  .card-price {
    font-size: 0.7rem;
  }
}
