.translation-results {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 10px;
}
.translation-results ul {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.translation-results li {
  margin-right: 10px;
  background-color: #f0f0f0;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}

body, html {
  width: 100%;
  height: 100%;
  overflow-x: hidden; /* Evita el desbordamiento horizontal */
}

.App {

}

.App-logo {
  height: 80px; /* Adjusted for better proportionality */
  animation: App-logo-spin infinite 20s linear; /* Keep the logo spinning for a dynamic effect */
}

.App-header {
  background-color: #fff; /* Clean white header for a sharp contrast */
  padding: 20px 0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Subtle shadow for depth */
  width: 100%; /* Ensure header spans the full width */
}

.App-link {
  color: #007bff; /* Bright, inviting link color */
}

/* Responsive banner image */
.banner img {
  width: 100%; /* Ensures the image is never wider than its container */
  height: auto; /* Maintains aspect ratio */
  object-fit: cover; /* Covers the area without stretching. Might crop the image */
  object-position: center; /* Adjust based on the focus area of your banner */
}

@media (max-width: 768px) {
  .banner img {
    height: 150px; /* Smaller screens get a smaller banner */
  }
}

/* Keyframes for logo animation */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  .App-header {
    padding: 10px 0; /* Reduce padding on smaller screens */
  }

  .App-logo {
    height: 60px; /* Scale down logo for smaller devices */
  }
}

/* Additional styling for other elements as needed */
.banner-container {
  max-width: 100%;
  overflow: hidden; /* Prevents horizontal scrolling */
}

/* Toggle Sidebar Button */
.toggle-sidebar-button {
  position: fixed;
  top: 15px;
  right: 10px;
  z-index: 1000;
  padding: 0.5em;
  font-size: 1.2em;
  color: #ffffff;
  background-color: #007bff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-sidebar-button::after {
  content: attr(data-count);
  background-color: #ff4136;
  color: #ffffff;
  font-size: 0.8em;
  padding: 0.2em 0.4em;
  border-radius: 50%;
  margin-left: 0.3em;
}

@media (max-width: 768px) {
  .toggle-sidebar-button {
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    font-size: 1em;
    padding: 0;
  }

  .toggle-sidebar-button::after {
    font-size: 0.7em;
    padding: 0.2em;
    margin-left: 0;
  }
}

.sidebar {
  position: fixed;
  top: 0;
  right: -100%;
  width: 80%;
  max-width: 320px;
  height: 100%;
  background-color: #fdfdfd;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden;
  transition: right 0.3s ease;
  z-index: 999;
  padding: 1em;
  padding-top: 4em; /* Prevent overlap with toggle button */
}

.sidebar-open {
  right: 0;
}
