
.card-list-container {
  overflow-x: auto;
  padding: 10px;
}
.translation-results {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 10px;
}
.translation-results ul {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.translation-results li {
  margin-right: 10px;
  background-color: #f0f0f0;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}
/* Styles for the loader overlay */
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's above other content */
  transition: opacity 0.3s ease; /* Add a smooth fade-in effect */
  opacity: 1; /* Initially visible */
}

/* Styles for the loader spinner */
.loader {
  border: 4px solid #3498db;
  border-top: 4px solid #e74c3c;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

/* Keyframes for the loader spinner animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Hide the loader overlay when images are loaded */
.images-loaded .loader-overlay {
  opacity: 0;
  pointer-events: none;
}

/* Search Input */
.search-input {
  width: 100%; /* Full width */
  padding: 8px 10px;
  border: 1px solid #dddddd;
  border-radius: 4px;
  box-sizing: border-box; /* To maintain width with padding */
  /* Additional styles for the search input */
}

/* Suggestions List */
.suggestions-list {
  position: absolute;
  top: 100%; /* Positioned right below the input box */
  left: 0;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #dddddd;
  border-top: none;
  z-index: 100; /* To ensure it appears above other content */
  max-height: 300px;
  overflow-y: auto; /* Enable scroll for long lists */
}

.suggestion-item {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.suggestion-item:hover {
  background-color: #f0f0f0;
}

.no-results {
  padding: 10px;
  text-align: center;
  color: #888888;
}

.header {
  text-align: center; /* Center align the logo */
  padding: 20px 0; /* Add some padding around the logo */
}

.logo {
  width: 150px; /* Adjust the size as needed */
  height: auto; /* Maintain aspect ratio */
}