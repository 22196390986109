/* src/pages/StatisticsPage.css */
.statistics-page {
  padding: 20px;
  background-color: #0f172a; /* Cambia el fondo para que coincida con el tema */
  color: #fff; /* Asegura que el texto sea blanco */
}

.statistics-page h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-weight: 700; /* Asegura que el título sea consistente */
  text-align: center; /* Centra el título */
  color: #3b82f6; /* Usa el mismo color que el título principal */
}

.statistics-page section {
  margin-bottom: 20px;
}

.statistics-page ul {
  list-style: none;
  padding: 0;
}

.statistics-page li {
  margin-bottom: 10px; /* Añade espacio entre los elementos de la lista */
}

.statistics-page table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px; /* Añade espacio superior a la tabla */
}

.statistics-page table th,
.statistics-page table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.statistics-page table th {
  background-color: #1f2937; /* Cambia el fondo del encabezado de la tabla */
  text-align: left;
  color: #fff; /* Asegura que el texto del encabezado sea blanco */
}

.statistics-page table td {
  background-color: #2d3748; /* Cambia el fondo de las celdas de la tabla */
  color: #fff; /* Asegura que el texto de las celdas sea blanco */
}